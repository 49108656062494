<template>
  <div id="shopSet">
    <div class="logo-box">
      <div class="shop-logo">
        <p>店铺LOGO</p>
        <input @change="logoUpload" ref="logoInput" type="file" accept="image/png, image/jpeg" />
        <img @click="uploadLogo" v-lazy="logoSrc" alt />
      </div>
    </div>

    <div class="info-box">
      <div class="info-item">
        <p class="title">店铺名称</p>
        <input v-model="shopName" type="text" placeholder="请填写您的店铺名称" />
      </div>

      <div class="info-item">
        <p class="title">营业时间</p>
        <div class="time-box">
          <p
            :class="startVal === '09:00' ? '' :  'active'"
            @click="showStartPicker = true"
          >{{startVal}}</p>
          <span>至</span>
          <p :class="endVal === '17:00' ? '' :  'active'" @click="showEndPicker = true">{{endVal}}</p>
        </div>
      </div>

      <div class="info-item">
        <p class="title">起送价格</p>
        <div class="right-item">
          <input v-model="deliverPrice" type="number" placeholder="请设置起送价格，默认30元起送" />
          <span>元</span>
        </div>
      </div>
    </div>

    <div class="info-box">
      <div class="info-item">
        <p class="title">配送费</p>
        <div class="right-item">
          <input v-model="deliveryPrice" type="text" placeholder="默认0元免配送费" />
          <span>元</span>
        </div>
      </div>

      <div class="info-item info-switch">
        <div>
          <p class="title">免配送费</p>
          <van-field name="switch">
            <template #input>
              <van-switch v-model="freeSwitch" size="20" active-color="#FF6737" />
            </template>
          </van-field>
        </div>

        <p>订单实付金额达到指定额度可免费配送</p>
      </div>

      <div class="info-item" v-if="freeSwitch">
        <p class="title">免配门槛</p>
        <div class="right-item">
          <input v-model="deliverFree" type="number" min placeholder="默认实付满50元免配送费" />
          <span>元</span>
        </div>
      </div>
    </div>

    <div class="submit-btn" @click="submit">保存修改</div>

    <van-popup v-model="showStartPicker" position="bottom">
      <van-datetime-picker
        @change="startChange"
        type="time"
        v-model="startVal"
        :max-hour="maxStartHour"
        :max-minute="maxStartMinute"
        @confirm="onStartConfirm"
        @cancel="showStartPicker = false"
      />
    </van-popup>
    <van-popup v-model="showEndPicker" position="bottom">
      <van-datetime-picker
        @change="endChange"
        type="time"
        v-model="endVal"
        :min-minute="minEndMinute"
        :min-hour="minEndHour"
        @confirm="onEndConfirm"
        @cancel="showEndPicker = false"
      />
    </van-popup>
  </div>
</template>

<script>
import { Toast } from "vant";
import { userInfo, shopInfoEdit } from "../api/requist";
export default {
  components: {},
  props: [],
  data() {
    return {
      deliverFree: "",
      freeSwitch: false,
      deliveryPrice: "",
      shopName: "",
      minEndMinute: 0,
      minEndHour: 0,
      maxStartHour: 23,
      maxStartMinute: 59,
      startVal: "09:00",
      endVal: "17:00",
      showStartPicker: false,
      showEndPicker: false,
      deliverPrice: "",
      logoSrc: require("@images/upload.png"),
      licenseFile: "",
      timer: null
    };
  },
  created() {},
  mounted() {
    this.init();
    this.getUser();
  },
  watch: {},
  computed: {},
  methods: {
    defn(fn, delay) {
      this.timer = null;
      return () => {
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
          fn.call(this, ...arguments);
        }, delay);
      };
    },
    init() {
      let start = this.startVal.split(":");
      let end = this.endVal.split(":");
      this.maxStartHour = +end[0];
      this.minEndHour = +start[0];
      // console.log(this.startVal, this.endVal);
      // console.log(this.maxStartHour);
    },

    async getUser() {
      let formData = new FormData();
      formData.append("merch_id", localStorage.getItem("merch_id"));
      let res = await userInfo(formData);
      if (res.code === 200) {
        this.shopName = res.data.market_name;
        this.startVal = res.data.start_time;
        this.endVal = res.data.end_time;
        this.logoSrc = res.data.market_logo;
        this.deliverPrice = res.data.starting_amount;
        this.freeSwitch = res.data.is_free === "0" ? true : false;
        this.deliveryPrice = res.data.delivery_fee;
        this.deliverFree = this.freeSwitch ? res.data.delivery_money : 0;

        if (res.data.market_logo) {
          this.licenseFile = res.data.market_logo;
        }
      }
    },

    startChange() {
      let start = this.startVal.split(":");
      let end = this.endVal.split(":");
      this.maxStartHour = +end[0];
      this.minEndHour = +start[0];
      if (+start[0] === +end[0]) {
        this.maxStartMinute = +end[1];
      }
    },

    endChange() {
      let start = this.startVal.split(":");
      let end = this.endVal.split(":");
      this.maxStartHour = +end[0];
      this.minEndHour = +start[0];
      if (+start[0] === +end[0]) {
        this.minEndMinute = +start[1];
      }
    },
    onStartConfirm(time) {
      this.startVal = time;
      this.$nextTick(() => {
        this.showStartPicker = false;
      });
    },

    onEndConfirm(time) {
      this.endVal = time;
      this.$nextTick(() => {
        this.showEndPicker = false;
      });
    },
    logoUpload(e) {
      let that = this;
      this.licenseFile = e.target.files[0];
      var file = e.target.files[0];
      if (window.FileReader) {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        //监听文件读取结束后事件
        reader.onloadend = function(e) {
          //e.target.result就是最后的路径地址
          that.logoSrc = e.target.result;
        };
      }
    },
    uploadLogo() {
      this.$refs.logoInput.dispatchEvent(new MouseEvent("click"));
    },

    submitFn() {
      this.defn(this.submit, 5000)();
    },

    async submit() {
      if (this.licenseFile === "") {
        return Toast("请上传店铺Logo");
      }

      if (this.startVal === "") {
        return Toast("请填写营业开始时间");
      }

      if (this.endVal === "") {
        return Toast("请填写营业结束时间");
      }

      if (this.deliverPrice === "") {
        return Toast("请填写起送价格");
      }

      let formData = new FormData();
      formData.append("merch_id", localStorage.getItem("merch_id"));
      formData.append("market_logo", this.licenseFile);
      formData.append("market_name", this.shopName);
      formData.append("start_time", this.startVal);
      formData.append("end_time", this.endVal);
      formData.append(
        "starting_amount",
        this.deliverPrice ? this.deliverPrice : 0
      // this.deliverPrice ? this.deliverPrice : 30
      );
      formData.append("is_free", this.freeSwitch ? 0 : 1);
      formData.append(
        "delivery_fee",
        this.deliveryPrice ? this.deliveryPrice : 0
      );
      formData.append(
        "delivery_money",
        this.deliverFree ? this.deliverFree : 0
      // this.deliverFree ? this.deliverFree : 50
      );
      let res = await shopInfoEdit(formData);
      Toast(res.message);
    }
  }
};
</script>
<style lang="scss" >
@import "../common/css/theme.scss";
#shopSet {
  padding-top: 12px;
  box-sizing: border-box;
  width: 100%;
  min-height: 100vh;
  background: rgba(245, 247, 250, 1);
  .logo-box {
    width: 100%;
    background: rgba(255, 255, 255, 1);
  }
  .shop-logo {
    height: 18.93 * 3.75px;
    width: calc(100vw - 16px * 2);
    margin: 0 auto;
    box-sizing: border-box;
    background: rgba(255, 255, 255, 1);
    display: flex;
    align-items: center;
    justify-content: space-between;
    p {
      color: #303133;
      font-size: 14px;
    }
    input {
      height: 0;
      width: 0;
    }
    img {
      object-fit: cover;
      border-radius: 50%;
      height: 16 * 3.75px;
      width: 16 * 3.75px;
    }
  }
  .info-box {
    margin-top: 12px;
    background: rgba(255, 255, 255, 1);
  }

  .info-item {
    margin: 0 auto;
    height: 44px;
    width: calc(100vw - 16px * 2);
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    background: rgba(255, 255, 255, 1);
    border-bottom: 1px solid rgba(245, 247, 250, 1);
    input {
      padding: 0 !important;
    }
    .right-item {
      display: flex;
      align-items: center;
      input {
        height: 44px;
        line-height: 44px;
      }
      span {
        height: 44px;
        line-height: 44px;
        box-sizing: border-box;
        height: 100%;
        display: block !important;
        font-size: 14px;
      }
    }
    span {
      height: 100%;
      display: block;
      margin-left: 1.07 * 3.75px;
      font-size: 14px;
      color: rgba(144, 147, 153, 1);
    }
    &.info-switch {
      padding: 12px 0;
      box-sizing: border-box;
      height: 17.33 * 3.75px;
      display: block;
      & > div {
        margin-bottom: 1.07 * 3.75px;
        height: 5.33 * 3.75px;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      .van-cell {
        width: 15.67 * 3.75px;
      }

      p {
        color: rgba(144, 147, 153, 1);
        font-size: 12px;
        text-align: left;
      }
    }
    .title {
      color: #303133;
      font-size: 14px;
    }

    .time-box {
      display: flex;
      align-items: center;
      p {
        color: #c0c4cc;
        font-size: 14px;
        &.active {
          color: #303133;
        }
      }

      span {
        color: #909399;
        font-size: 14px;
        margin: 0 12px;
      }
    }

    .right-box {
      align-items: center;
      display: flex;
      p {
        margin-right: 12px;
        color: #c0c4cc;
        font-size: 14px;
        &.active {
          color: #303133;
        }
      }
      img {
        width: 1.55 * 3.75px;
        height: 2.71 * 3.75px;
      }
    }

    input {
      line-height: 44px;
      box-sizing: border-box;
      padding: 12px 0;
      width: 53.7 * 3.75px;
      box-sizing: border-box;
      height: 100%;
      color: #303133;
      font-size: 14px;
      border: none;
      outline: none;
      text-align: right;
      &::placeholder {
        color: rgba(192, 196, 204, 1);
        font-size: 14px;
      }
    }
  }
  .submit-btn {
    width: 91.47 * 3.75px;
    height: 10.67 * 3.75px;
    background: $theme-color;
    opacity: 1;
    border-radius: 1 * 3.75px;
    font-size: 14px;
    line-height: 10.67 * 3.75px;
    color: rgba(255, 255, 255, 1);
    text-align: center;
    margin: 8 * 3.75px auto 0;
  }
}
</style>
